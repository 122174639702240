$site-primary: #303030;    
$site-primary-text: white; 
$site-secondary: #FCCB1A; 
$site-highlight: #f68b1f;

$input-color: black;
$input-background:white;
$input-border-color: #ced4da; //#6e6e6e
$input-border: 1px solid $input-border-color;
$input-border-hover: solid 1px $site-primary;
$input-border-focus: solid 1px $site-primary;
$input-max-width: 400px;
$input-border-radius: 5px;  
$input-text-color: #2e2e2e;
$input-fieldset: #406767;